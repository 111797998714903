import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  AppleLoginButtonComponent,
  FrontendAuthUiModule,
  LoginOrComponent,
} from '@memberspot/frontend/auth/ui';
import { FrontendSharedUiControlsModule } from '@memberspot/frontend/shared/ui/controls';
import { ToolsModule } from '@memberspot/frontend/shared/ui/tools';
import { FeatureToggleEnabledDirective } from '@memberspot/frontend/shared/util/feature-toggle';
import { TranslocoModule } from '@ngneat/transloco';

import { GoogleSigninDirective } from './components/google-signin.directive';
import { LoginComponent } from './components/login/login.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignOutDirective } from './components/signout.directive';

const components = [SigninComponent, LoginComponent, GoogleSigninDirective];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    ToolsModule,
    TranslocoModule,
    FrontendAuthUiModule,
    FrontendSharedUiControlsModule,
    RouterModule,
    LoginOrComponent,
    SignOutDirective,
    AppleLoginButtonComponent,
    FeatureToggleEnabledDirective,
  ],
  exports: [...components, SignOutDirective],
})
export class FrontendAuthFeatureLoginModule {}
