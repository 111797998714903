import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToolsModule } from '@memberspot/frontend/shared/ui/tools';

import { LoginLogoContainerComponent } from './login-logo-container/login-logo-container.component';

@NgModule({
  declarations: [LoginLogoContainerComponent],
  imports: [CommonModule, ToolsModule],
  exports: [LoginLogoContainerComponent],
})
export class FrontendAuthUiModule {}
