export const getCookieMap = (doc: Document) =>
  doc.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);

    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});

export const getCookieKey = (key: string, doc: Document) => {
  const cookieMap = getCookieMap(doc);

  return cookieMap[key];
};
