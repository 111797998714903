import { inject, Injectable } from '@angular/core';
import { Auth, user } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { LOCAL_STORAGE, LOCATION, SESSION_STORAGE } from '@ng-web-apis/common';
import { filter, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  #localStorage = inject(LOCAL_STORAGE);
  #sessionStorage = inject(SESSION_STORAGE);
  #location = inject(LOCATION);

  constructor(
    private afAuth: Auth,
    private router: Router,
  ) {
    this.watchAuthState();
  }

  watchAuthState() {
    let wasLoggedIn = false;

    user(this.afAuth)
      .pipe(
        tap((as) => (as ? (wasLoggedIn = true) : null)),
        filter((as) => !as),
        filter(() => wasLoggedIn),
      )
      .subscribe(() => {
        console.log('navigating to login');
        this.router.navigateByUrl('auth/login?force=true');
        this.#localStorage.clear();
        this.#sessionStorage.clear();
        this.#location.reload();
        wasLoggedIn = false;
      });
  }

  resetState(add: string[] = []) {
    console.log('Resetting store');
    resetStores({
      exclude: ['memberarea', 'router', ...add],
    });
  }
}
