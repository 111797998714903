import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'mspot-login-or',
  standalone: true,
  imports: [TranslocoModule],
  templateUrl: './login-or.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'flex items-center' },
})
export class LoginOrComponent {
  @Input() translationKey = 'login-or';
}
