import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  getIdToken,
} from '@angular/fire/auth';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import {
  AppleLogin,
  GoogleLogin,
  LoginService,
} from '@memberspot/frontend/auth/feature/login';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { getCookieKey } from '@memberspot/frontend/shared/util/cookie';
import {
  AdminUserEndpoints,
  CreateProfileDto,
} from '@memberspot/shared/model/admin-user';
import { Language } from '@memberspot/shared/model/types';
import { WINDOW } from '@ng-web-apis/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { getRedirectResult, UserCredential } from 'firebase/auth';
import { from, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RegisterService implements GoogleLogin, AppleLogin {
  get refLink() {
    return this.routerQuery.getQueryParams('fpr');
  }

  constructor(
    private afAuth: Auth,
    private http: HttpBackendService,
    private routerQuery: RouterQuery,
    private loginService: LoginService,
    @Inject(DOCUMENT) private doc: Document,
    @Inject(WINDOW) private window: Window,
    private gtmService: GoogleTagManagerService,
  ) {}

  appleLogin(): Observable<UserCredential> {
    return from(this.loginService.appleLogin()).pipe(
      switchMap((u) => {
        if (u) {
          return this._checkIfUserIsNew(u);
        }

        return of(null);
      }),
    );
  }

  async checkIfRedirected() {
    const res = await getRedirectResult(this.afAuth);

    console.log(res);

    if (res !== null) {
      this._checkIfUserIsNew(res);
    }
  }

  signUp(
    firstName: string,
    name: string,
    email: string,
    password: string,
    newsletter: boolean,
    phone?: string,
  ) {
    return from(
      createUserWithEmailAndPassword(this.afAuth, email, password),
    ).pipe(
      switchMap(() =>
        this._createProfile(firstName, name, newsletter, this.refLink, phone),
      ),
      tap(
        () =>
          this.afAuth.currentUser && getIdToken(this.afAuth.currentUser, true),
      ),
    );
  }

  googleLogin() {
    return from(this.loginService.googleLogin()).pipe(
      switchMap((u) => {
        if (u) {
          return this._checkIfUserIsNew(u);
        }

        return of(null);
      }),
    );
  }

  private _checkIfUserIsNew(u: UserCredential) {
    if (getAdditionalUserInfo(u)?.isNewUser) {
      const names = u.user?.displayName?.split(' ');

      return this._createProfile(
        names?.[0] || '',
        names?.[1] || '',
        false,
        this.refLink,
      );
    }

    return of(null);
  }

  private _createProfile(
    firstName: string,
    name: string,
    newsletter: boolean,
    ref?: string,
    phone?: string,
  ) {
    this.gtmService
      .pushTag({
        event: 'signuptrigger',
      })
      .catch((err) => console.error(err));

    let refLink = ref;

    if (!refLink) {
      const fprProm = getCookieKey('_fprom_code', this.doc);

      if (fprProm) {
        refLink = (fprProm as string)?.replace('_r_', '');
      }
    }

    const profileDto: CreateProfileDto = {
      firstName,
      name,
      refLink,
      newsletter,
      language: this._getLanguage(),
      host: this.window.location.hostname,
      phone: phone,
    };

    return this.http.generic(AdminUserEndpoints.CREATE_PROFILE(profileDto));
  }

  private _getLanguage(): Language {
    return /^de\b/.test(this.window.navigator.language)
      ? Language.De
      : Language.Eng;
  }
}
