import { Directive, HostListener, inject } from '@angular/core';
import { Auth, signOut } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { clearSentryUserInfo } from '@memberspot/frontend/shared/util/sentry';
import { WINDOW } from '@ng-web-apis/common';

import { SessionService } from '../session.service';

@Directive({
  selector: '[mspotSignOut]',
  standalone: true,
})
export class SignOutDirective {
  private _afAuth = inject(Auth);
  private _router = inject(Router);
  private _session = inject(SessionService);
  private _window = inject(WINDOW);

  @HostListener('click')
  async onClick() {
    await signOut(this._afAuth);
    clearSentryUserInfo();
    await this._router.navigateByUrl('/auth/login?force=true');
    console.log('signed out, reload');
    this._window.sessionStorage.clear();
    this._window.localStorage.clear();
    this._window.location.reload();
  }
}
