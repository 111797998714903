const prefix = 'Register';

export const REGISTER_EVENTS = {
  START: `${prefix}:start`,
  TRUST: `${prefix}:trust`,
  APPLE_LOGIN: `${prefix}:apple_login`,
  GOOGLE_LOGIN: `${prefix}:google_login`,
  PHONE_NUMBER: `${prefix}:phone_number`,
  MARKETING_CONSENT: `${prefix}:marketing:consent`,
  ACCOUNT: `${prefix}:account`,
  FORGOT_PASSWORD: `${prefix}:account:forgot_password`,
  LOGIN: `${prefix}:account:login`,
  GOOGLE_REGISTER: `${prefix}:account:google_login`,
  REGISTER_FIRST: `${prefix}:account:register_first`,
  EXISTING_ACCOUNT: `${prefix}:existing:account`,
  SUCCESS: `${prefix}:success`,
};
