import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Auth, signInWithEmailLink } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'mspot-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SigninComponent implements OnInit {
  constructor(
    public afAuth: Auth,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit() {
    const url = this.router.url;

    this.confirmSignIn(url);
  }

  async confirmSignIn(url: string) {
    const email = await this.route.paramMap
      .pipe(
        first(),
        map((rp) => rp.get('email')),
      )
      .toPromise();

    if (!email) {
      return;
    }

    try {
      await signInWithEmailLink(this.afAuth, email, url);
    } catch (err) {
      this.router.navigate(['']);
      console.error(err);
    }
  }
}
