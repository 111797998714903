<ng-container
  *ngTemplateOutlet="!isAdmin && useCardWrapper ? wrappedContent : cleanContent"
>
</ng-container>

<!-- main content wrapped -->
<ng-template #wrappedContent>
  <mspot-nav-card>
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </mspot-nav-card>
</ng-template>

<ng-template #cleanContent>
  <div class="p-6">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<!-- main content -->
<ng-template #content>
  <div class="text-center">
    @if (loginConfig) {
      <div
        class="login-img-container"
        [ngStyle]="{
          'background-image':
            'url(' +
            (loginConfig
              ? (loginConfig | async)?.logoUrl ||
                (loginConfig | async)?.headerLogoUrl ||
                defaultLogo
              : defaultLogo) +
            ')'
        }"
      ></div>
    } @else {
      <div class="mb-3 mt-4 flex justify-center">
        <img
          class="h-8"
          src="assets/svgs/mspot-logo.svg"
          alt="logo"
          data-cy="loginLogoContainerComponentAdminLogo"
        />
      </div>
    }

    <h4
      class="text-base font-normal text-gray-700"
      data-cy="loginLogoContainerComponentTitle"
    >
      {{ title }}
    </h4>
  </div>

  <ng-content></ng-content>
</ng-template>

<!-- Admin Logo -->
