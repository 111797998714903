import { BackendMethod } from '@memberspot/shared/model/backend';

import { ResetPasswordDto } from '../../dtos/auth/reset-password.dto';

const Emails = {
  resetPassword: () => `/emails/reset-password`,
  RESET_PASSWORD: (data: ResetPasswordDto, schoolId?: string | null) => ({
    url: `/emails/reset-password${schoolId ? '/' + schoolId : ''}`,
    data,
    method: BackendMethod.POST_UNAUTH,
  }),
};

export const SharedEndpoints = Object.freeze({
  Emails,
});
