import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  Optional,
} from '@angular/core';
import { Auth } from '@angular/fire/auth';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  LOGIN_CARD_STYLE,
  LOGIN_CONFIG,
} from '@memberspot/frontend/shared/feature/services';
import { LoadingState } from '@memberspot/frontend/shared/ui/tools';
import {
  REGISTER_EVENTS,
  UserTrackingService,
} from '@memberspot/frontend/shared/user-tracking';
import { FormControls } from '@memberspot/frontend/shared/util/form';
import { NotiService } from '@memberspot/frontend/shared/util/noti';
import { IS_ADMIN } from '@memberspot/frontend/shared/util/tokens';
import { exampleLogoPath, LoginConfig } from '@memberspot/models';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { first, switchMap, take, tap } from 'rxjs/operators';

import {
  APPLE_LOGIN_PROVIDER,
  AppleLogin,
  AZURE_AD_LOGIN_PROVIDER,
  AzureAdLogin,
  GOOGLE_LOGIN_PROVIDER,
  GoogleLogin,
  LoginService,
} from '../../login.service';

type LoginType = 'login' | 'reset';

@Component({
  selector: 'mspot-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {
  private _type: LoginType = 'login';
  #userTrackingService = inject(UserTrackingService);

  form: UntypedFormGroup = this.fb.group({
    email: FormControls.EmailField,
    password: FormControls.PasswordField,
  });

  loadingState = LoadingState;
  loadingState$ = new BehaviorSubject<LoadingState>(LoadingState.CLEAR);

  authCode?:
    | 'auth/wrong-password'
    | 'auth/email-already-in-use'
    | 'auth/user-not-found'
    | 'auth/too-many-requests'
    | 'auth/account-exists-with-different-credential'
    | 'auth/popup-closed-by-user'
    | 'auth/user-disabled'
    | null;

  get type() {
    return this._type;
  }

  set type(val: LoginType) {
    this.authCode = null;
    this.setLoading(false);
    this._type = val;
    this.form?.markAsPristine();
  }

  get email() {
    return this.form.get('email') as UntypedFormControl;
  }

  get password() {
    return this.form.get('password') as UntypedFormControl;
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get defaultLogo() {
    return exampleLogoPath;
  }

  constructor(
    private fb: UntypedFormBuilder,
    public afAuth: Auth,
    private loginService: LoginService,
    private cdr: ChangeDetectorRef,
    private noti: NotiService,
    @Optional()
    @Inject(LOGIN_CONFIG)
    public loginConfig: Observable<LoginConfig>,
    @Optional() @Inject(IS_ADMIN) public isAdmin: boolean,
    private translocoService: TranslocoService,
    @Inject(GOOGLE_LOGIN_PROVIDER) private googleLoginService: GoogleLogin,
    @Inject(AZURE_AD_LOGIN_PROVIDER) private azureLoginService: AzureAdLogin,
    @Inject(APPLE_LOGIN_PROVIDER) private _appleLoginService: AppleLogin,
    @Inject(LOGIN_CARD_STYLE) public cardStyle: { useCardWrapper: boolean },
  ) {}

  setLoading(state: boolean) {
    if (state) {
      this.loadingState$.next(LoadingState.LOADING);
    } else {
      this.loadingState$.next(LoadingState.CLEAR);
    }
  }

  setSuccess() {
    this.loadingState$.next(LoadingState.SUCCESS);
  }

  microsoftLogin() {
    this.azureLoginService.azureAdLogin().subscribe({
      error: (err) => {
        console.error(err.code);
        this.setAuthError(err);
      },
    });
  }

  googleLogin() {
    this.googleLoginService.googleLogin().subscribe();
    this.#userTrackingService.trackEvent(REGISTER_EVENTS.GOOGLE_REGISTER);
  }

  onResetClick() {
    this.#userTrackingService.trackEvent(REGISTER_EVENTS.FORGOT_PASSWORD);
    this.type = 'reset';
  }

  redirectToRegister() {
    this.#userTrackingService.trackEvent(REGISTER_EVENTS.REGISTER_FIRST);
  }

  signInWithApple() {
    this._appleLoginService.appleLogin().pipe(take(1)).subscribe();
    this.#userTrackingService.trackEvent(REGISTER_EVENTS.APPLE_LOGIN);
  }

  async onSubmit() {
    const email = (this.email?.value as string).replace(/\s/g, '');
    const password = this.password?.value;

    try {
      if (this.isLogin) {
        this.#userTrackingService.trackEvent(REGISTER_EVENTS.LOGIN);
        this.setLoading(true);
        await this.loginService.login(email, password);
        this.setSuccess();
      }

      if (this.isPasswordReset) {
        const obs = this.loginConfig || of(null);

        obs
          .pipe(
            first(),
            tap(() => this.setLoading(true)),
            switchMap((lc: LoginConfig | null) =>
              this.loginService.resetPasswordReq(email, lc?.schoolId),
            ),
            switchMap(() =>
              this.translocoService.selectTranslate(
                'auth.eMailSentNotification',
              ),
            ),
            first(),
          )
          .subscribe({
            next: (noti) => {
              this.setSuccess();
              this.noti.success(noti);
            },
            error: () => {
              this.setLoading(false);
              this.authCode = 'auth/user-not-found';
              this.cdr.markForCheck();
            },
          });
      }
    } catch (error: any) {
      this.setAuthError(error);
    }
  }

  private setAuthError(error: any) {
    this.setLoading(false);

    if (
      error.code === 'auth/internal-error' &&
      error.message.indexOf('Cloud Function') !== -1
    ) {
      this.authCode = 'auth/user-not-found';
    } else {
      this.authCode = error?.code;
    }

    console.error(error);
    this.cdr.detectChanges();
  }
}
