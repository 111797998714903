<button
  type="button"
  class="btn flex w-full items-center justify-center gap-2 bg-black text-white hover:bg-black/80"
>
  <img
    src="assets/svgs/apple_logo.svg"
    width="13"
    height="15"
    class="invert"
    alt="Apple Login"
  />
  <span> {{ labelKey() | transloco }} </span>
</button>
