<div class="flex-1">
  <div class="w-full border-t border-gray-300"></div>
</div>
<div class="relative flex justify-center text-sm">
  <span class="px-2 text-gray-500">
    {{ translationKey | transloco }}
  </span>
</div>
<div class="flex-1">
  <div class="w-full border-t border-gray-300"></div>
</div>
