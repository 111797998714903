import { Injectable, InjectionToken } from '@angular/core';
import {
  Auth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from '@angular/fire/auth';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import {
  ClientEndpoints,
  MemberRegisterDto,
  MemberRegisterReturnDto,
  ResetPasswordDto,
  SharedEndpoints,
} from '@memberspot/models';
import {
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  SAMLAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SessionService } from './session.service';

export interface GoogleLogin {
  googleLogin(): Observable<UserCredential>;
}

export interface AzureAdLogin {
  azureAdLogin(): Observable<UserCredential>;
}

export interface AppleLogin {
  appleLogin(): Observable<UserCredential>;
}

export const GOOGLE_LOGIN_PROVIDER = new InjectionToken<GoogleLogin>(
  'google login provider',
);

export const AZURE_AD_LOGIN_PROVIDER = new InjectionToken<AzureAdLogin>(
  'azure login provider',
);

export const APPLE_LOGIN_PROVIDER = new InjectionToken<AppleLogin>(
  'apple login provider',
);

@Injectable({
  providedIn: 'root',
})
export class LoginService implements GoogleLogin, AzureAdLogin, AppleLogin {
  constructor(
    private afAuth: Auth,
    private session: SessionService,
    private http: HttpBackendService,
  ) {}

  getRedirectResult() {
    return getRedirectResult(this.afAuth);
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.afAuth, email, password);
  }

  registerMember(schoolId: string, data: MemberRegisterDto) {
    return this.http
      .postUnAuth<MemberRegisterReturnDto>(
        ClientEndpoints.USER.REGISTER_MEMBER(schoolId),
        data,
      )
      .pipe(switchMap((res) => this.login(res.email, data.password)));
  }

  claimOffer(schoolId: string, offerId: string) {
    return this.http.generic(
      ClientEndpoints.USER.CLAIM_OFFER(schoolId, offerId),
    );
  }

  sendResetPasswordMail(email: string) {
    return sendPasswordResetEmail(this.afAuth, email);
  }

  resetPasswordReq(email: string, schoolId?: string | null) {
    const data: ResetPasswordDto = {
      email,
      schoolId,
    };

    return this.http.generic(
      SharedEndpoints.Emails.RESET_PASSWORD(data, schoolId),
    );
  }

  appleLogin() {
    return from(signInWithPopup(this.afAuth, new OAuthProvider('apple.com')));
  }

  googleLogin() {
    // return this.platform.IPAD || this.platform.ANDROID || this.platform.IOS
    //   ? from(signInWithRedirect(this.afAuth, new GoogleAuthProvider()))
    //   : from(signInWithPopup(this.afAuth, new GoogleAuthProvider()));
    return from(signInWithPopup(this.afAuth, new GoogleAuthProvider()));
  }

  azureAdLoginViaRedirect() {
    const provider = new SAMLAuthProvider('saml.azuread');

    return from(signInWithRedirect(this.afAuth, provider));
  }

  azureAdLogin() {
    // const provider = new OAuthProvider('microsoft.com');
    // provider.setCustomParameters({
    //   tenant: '1a7d7738-ca67-4cdf-8431-8ba664fbfbc4',
    // });

    const provider = new SAMLAuthProvider('saml.azuread');

    return from(signInWithPopup(this.afAuth, provider));

    // try {
    //   const res = await getRedirectResult(this.afAuth);
    //   console.log(res);

    //   if (res === null) {
    //     return from(signInWithRedirect(this.afAuth, provider));
    //   }
    // } catch (error) {
    //   return from(signInWithPopup(this.afAuth, provider));
    // }
  }

  resetState() {
    this.session.resetState(['auth']);
  }
}
