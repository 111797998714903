import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
} from '@angular/core';
import { LOGIN_CONFIG } from '@memberspot/frontend/shared/feature/services';
import { IS_ADMIN } from '@memberspot/frontend/shared/util/tokens';
import { defaultLogo, LoginConfig } from '@memberspot/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'mspot-login-logo-container',
  templateUrl: './login-logo-container.component.html',
  styles: [
    `
      .login-img {
        max-width: 100%;
      }
      .login-img-container {
        height: 65px;
        margin: 15px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLogoContainerComponent {
  @Input() useCardWrapper = true;

  @Input() title?: string;

  get defaultLogo() {
    return defaultLogo;
  }

  constructor(
    @Optional()
    @Inject(LOGIN_CONFIG)
    public loginConfig: Observable<LoginConfig>,
    @Optional()
    @Inject(IS_ADMIN)
    public isAdmin: boolean,
  ) {}
}
