import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoPipe } from '@ngneat/transloco';

@Component({
  selector: 'mspot-apple-login-button',
  standalone: true,
  imports: [TranslocoPipe],
  templateUrl: './apple-login-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'w-full block',
  },
})
export class AppleLoginButtonComponent {
  labelKey = input.required<string>();
}
